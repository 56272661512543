.error-container__oneline-text {
  padding: 2rem 3rem;
  font-size: 1.2rem;
  text-align: left;
}
.error-container__redline {
  color: #ac3c3c;
  font-size: 1.2rem;
  display: block;
  margin: 0.5rem 0;
  text-align: left;
}
.error-container__button {
  margin-top: 1rem;
  padding: 0.75rem 1.5rem;
  border: none;
  outline: none !important;
}
.floating-error-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 5000;
  background-color: rgba(20, 20, 20, 0.6);
}
.floating-error-container {
  position: fixed;
  top: 8rem;
  z-index: 9999;
  width: 70rem;
  left: calc(50% - 35rem);
  right: calc(50% - 35rem);
  text-align: center;
  padding: 2rem 4rem;
  -webkit-border-radius: 2rem;
  -moz-border-radius: 2rem;
  border-radius: 2rem;
  transition: 0.2s all linear;
  -moz-transition: 0.2s all linear;
  -ms-transition: 0.2s all linear;
  -o-transition: 0.2s all linear;
  -webkit-transition: 0.2s all linear;
  -webkit-box-shadow: 0 0.2rem 1rem 0.3rem rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0.2rem 1rem 0.3rem rgba(0, 0, 0, 0.2);
  box-shadow: 0 0.2rem 1rem 0.3rem rgba(0, 0, 0, 0.2);
}
.floating-error-container .floating-error__title {
  text-align: left;
  font-size: 2.5rem;
  border-bottom: 0.1rem solid rgba(153, 153, 153, 0.205);
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
}
.floating-error-container .floating-error__message {
  font-size: 1.4rem;
  text-align: left;
  margin-bottom: 1.5rem;
}
.floating-error-container .floating-error__missing-param-list {
  width: 100%;
  text-align: left;
  border: 0.1rem solid #ac4646;
  padding: 1rem;
  margin-bottom: 1rem;
}
.floating-error-container .floating-error__missing-param-list--element {
  display: block;
  font-size: 1.4rem;
}
.floating-error-container .floating-error__missing-param-list--element::before {
  content: " - ";
}
.floating-error-container .floating-error .socket-error-wrapper {
  -webkit-box-shadow: 0 0 1rem 0.3rem rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 1rem 0.3rem rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 1rem 0.3rem rgba(0, 0, 0, 0.2);
  border: none;
}
.floating-error-container .floating-error__button {
  font-size: 1.4rem;
  -webkit-border-radius: 2rem;
  -moz-border-radius: 2rem;
  border-radius: 2rem;
  border: none;
  outline: none !important;
}
@media screen and (max-width: 768px) {
  .floating-error-container {
    width: 30rem;
    left: calc(50% - 15rem);
    right: calc(50% - 15rem);
  }
}
